import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    minHeight: '100%',
    background: theme => `linear-gradient(to bottom, ${theme.palette.catskillWhite} 40%, ${theme.palette.background.default} 40%);`,
  },
};

export default styles;
