import NotAuthorizedLayout from 'layouts/NotAuthorized';

import dynamic from 'next/dynamic';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

 
const LoginNoSSR = dynamic(() => import('components/pages/login'), { ssr: false })

const LoginPage = () => <LoginNoSSR />;

export default LoginPage;

LoginPage.Layout = NotAuthorizedLayout;

export const getServerSideProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['login', 'validation', 'password'])),
  },
});

