import Box from 'components/common/Box';

import styles from './styles';

const NotAuthorizedLayout: FC = (props) => {
  const { children } = props;
  return (
    <Box sx={styles.root}>{children}</Box>
  );
};

export default NotAuthorizedLayout;
